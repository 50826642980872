import React from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { FIELDS, MAX_DATE } from '../../util/constants';
import styles from './styles.module.scss';
import { Loading } from '../loading';

export const View = ({
  handleSubmit,
  register,
  errors,
  onSubmit,
  file,
  setFile,
  allUsers,
  handleSelectedFile,
  isLoading,
}) => {
  return (
    <div className={styles['container']}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
        <h2>Registrar Documento</h2>

        <label htmlFor={FIELDS.company.name} className={styles['form-label']}>
          {FIELDS.company.label}
        </label>
        <select
          id={FIELDS.company.name}
          {...FIELDS.company}
          {...register(FIELDS.company.name, { ...FIELDS.company.rules })}
          className={styles['form-fields']}
        >
          <option value="" disabled selected>
            Selecciona un cliente
          </option>
          {allUsers?.map((e) => (
            <option value={e.value} key={e.id}>
              {e.label}
            </option>
          ))}
        </select>
        {errors?.company?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.company?.message} </span>
          </p>
        )}

        <label htmlFor={FIELDS.plate.name} className={styles['form-label']}>
          {FIELDS.plate.label}
        </label>
        <input
          type="text"
          {...FIELDS.plate}
          {...register(FIELDS.plate.name, { ...FIELDS.plate.rules })}
          id={FIELDS.plate.name}
          onChange={(e) => {
            e?.value?.target.toUpperCase();
          }}
          className={`${styles['form-fields']} ${styles['form-fields-upperscase']}`}
        />
        {errors?.plate?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.plate?.message} </span>
          </p>
        )}
        {errors?.plate?.type === 'maxLength' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.plate?.message} </span>
          </p>
        )}
        {errors?.plate?.type === 'pattern' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.plate?.message} </span>
          </p>
        )}

        <label
          htmlFor={FIELDS.typeVehicle.name}
          className={styles['form-label']}
        >
          {FIELDS.typeVehicle.label}
        </label>
        <select
          id={FIELDS.typeVehicle.name}
          {...FIELDS.typeVehicle}
          {...register(FIELDS.typeVehicle.name, {
            ...FIELDS.typeVehicle.rules,
          })}
          className={styles['form-fields']}
        >
          {FIELDS.typeVehicle.options.map((e) => (
            <option value={e.value} key={e.id}>
              {e.label}
            </option>
          ))}
        </select>
        {errors?.typeVehicle?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.typeVehicle?.message} </span>
          </p>
        )}

        <label
          htmlFor={FIELDS.typeDocument.name}
          className={styles['form-label']}
        >
          {FIELDS.typeDocument.label}
        </label>
        <select
          id={FIELDS.typeDocument.name}
          {...FIELDS.typeDocument}
          {...register(FIELDS.typeDocument.name, {
            ...FIELDS.typeDocument.rules,
          })}
          className={styles['form-fields']}
        >
          {FIELDS.typeDocument.options.map((e) => (
            <option value={e.value} key={e.id}>
              {e.label}
            </option>
          ))}
        </select>
        {errors?.typeDocument?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.typeDocument?.message} </span>
          </p>
        )}

        <label htmlFor={FIELDS.date.name} className={styles['form-label']}>
          {FIELDS.date.label}
        </label>
        <input
          type="date"
          max={MAX_DATE}
          {...FIELDS.date}
          {...register(FIELDS.date.name, { ...FIELDS.date.rules })}
          id={FIELDS.date.name}
          className={styles['form-fields']}
        />
        {errors?.date?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.date?.message} </span>
          </p>
        )}

        <label
          htmlFor={FIELDS.uploadFile.name}
          className={
            file?.name?.length > 0
              ? styles['form-uploader-disable']
              : styles['form-uploader']
          }
        >
          {FIELDS.uploadFile.label}
        </label>
        {errors?.uploadFile?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.uploadFile?.message} </span>
          </p>
        )}
        <input
          {...FIELDS.uploadFile}
          {...register(FIELDS.uploadFile.name, { ...FIELDS.uploadFile.rules })}
          id={FIELDS.uploadFile.name}
          className={styles['form-file']}
          onChange={(e) => handleSelectedFile(e)}
          disabled={file?.name?.length > 0}
        />
        {file?.name?.length > 0 && (
          <div className={styles['form-list']}>
            <div className={styles['form-list-group']}>
              <UploadFileIcon fontSize="small" />
              <span>{file?.name}</span>
            </div>

            <button
              className={styles['form-list-delete']}
              onClick={() => {
                document.getElementById('uploadFile').value = '';
                setFile(null);
              }}
            >
              <DeleteIcon fontSize="small" />
            </button>
          </div>
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <input type="submit" className={styles['form-submit']} />
        )}
      </form>
    </div>
  );
};
