import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppRouter } from './routers/AppRouter';
import { UserContext } from './contex/userContext';
import { useState } from 'react';
import { hasTokenExpired } from './util/hasTokenExpired';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [user, setUser] = useState({});

  useEffect(() => {
    if (hasTokenExpired()) {
      const sessionUser = JSON.parse(sessionStorage.getItem('user'));
      setUser(sessionUser);
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UserContext.Provider
        value={{
          user,
          setUser,
        }}
      >
        <AppRouter />
        <ToastContainer />
      </UserContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
