import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';

import styles from './styles.module.scss';

export const DeleteModal = ({
  id,
  isOpen,
  handleClose,
  handleOpen,
  handlerDelete,
  message,
}) => {
  return (
    <>
      <div className={styles['icon']}>
        <DeleteIcon onClick={handleOpen} />
      </div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className={styles['container']}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {message}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '16px',
            }}
          >
            <Button
              onClick={() => handlerDelete(id)}
              sx={{ marginLeft: '12px' }}
            >
              Aceptar
            </Button>
            <Button onClick={handleClose}>Cancelar</Button>
          </Box>
        </div>
      </Modal>
    </>
  );
};
