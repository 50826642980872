import React, { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { View } from './view';
import { useAllUsers } from '../../hooks/useAllUsers';
import { UserContext } from '../../contex/userContext';
import { formatDate } from '../../util/formatDate';
import { hasTokenExpired } from '../../util/hasTokenExpired';
import { loadFile } from '../../services';
import { EXPIRED_TOKEN, MESSAGE_DOCUMENTS } from '../../util/constants';
import { toast } from 'react-toastify';

export const RegisterDocument = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { users } = useAllUsers();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      company: '',
      plate: '',
      date: '',
    },
  });

  const allUsers = useMemo(() => {
    return users?.map((e) => {
      return {
        id: e?.user_id,
        label: e?.name,
        value: e?.user_id,
      };
    });
  }, [users]);

  const handleSelectedFile = (event) => {
    if (event) {
      return setFile(event.target.files[0]);
    }
  };

  const onSubmit = (data) => {
    if (!hasTokenExpired()) {
      (() => toast.error(EXPIRED_TOKEN))();
      setTimeout(() => navigate('/'), 1000);
    } else {
      setIsLoading(true);

      let raw = JSON.stringify({
        placa: data?.plate?.toUpperCase(),
        user_id: data?.company,
        cda_document_type: data?.typeDocument,
        vehicle_type: data?.typeVehicle,
        expedition_date: formatDate(data?.date),
      });

      let formdata = new FormData();
      formdata.append('file', file);
      formdata.append('body', raw);

      loadFile({ formdata: formdata, token: user.token })
        .then((result) => {
          setIsLoading(false);
          if (result.status === 201) {
            (() => {
              toast.success(MESSAGE_DOCUMENTS.LOAD);
              setFile(null)
              reset({
                company: '',
                plate: '',
                date:'',
              })
            })();
          } else {
            (() => toast.error(MESSAGE_DOCUMENTS.LOAD_ERROR))();
          }
        })
        .catch((error) => {
          (() => toast.error(MESSAGE_DOCUMENTS.LOAD_ERROR))();
          setIsLoading(false);
        });
    }
  };

  const props = {
    handleSubmit,
    register,
    isValid,
    errors,
    onSubmit,
    file,
    setFile,
    allUsers,
    handleSelectedFile,
    isLoading,
  };
  return <View {...props} />;
};
