import React, { useContext, useState } from 'react';
import { View } from './view';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../contex/userContext';
import { hasTokenExpired } from '../../util/hasTokenExpired';
import { updateUser } from '../../services';
import { EXPIRED_TOKEN, MESSAGE_USERS } from '../../util/constants';
import { toast } from 'react-toastify';

export const UpdateUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePass, setUpdatePass] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: state?.name ? state.name : '',
      nit: state?.nit ? state.nit : '',
      typeUser: state?.type === 'Admin' ? 'ROLE_ADMIN' : 'ROLE_USER',
      passCreate: state?.pass ? state.pass : '',
      passConfirm: state?.pass ? state.pass : '',
    },
  });
  const [isEnabled, setIsEnabled] = useState(
    state?.enabled === 'Si' ? true : false
  );

  const handleClickShowPassword = (e, isPassCreate) => {
    e.preventDefault();
    return isPassCreate
      ? setShowPassword((show) => !show)
      : setShowPasswordConfirm((show) => !show);
  };

  const pass = watch('passCreate');
  const isEdit = pathname === '/edit-user';
  const isDisabled = isEdit ? (updatePass ? !isValid : false) : !isValid;

  const onSubmit = async (data) => {
    if (!hasTokenExpired()) {
      (() => toast.error(EXPIRED_TOKEN))();
      setTimeout(() => navigate('/'), 1000);
    } else {
      setIsLoading(true);
      let raw = JSON.stringify({
        name: data?.name?.toUpperCase(),
        password: data?.passCreate,
        enabled: isEnabled,
      });
      updateUser({ nit: state?.nit, raw: raw, token: user.token })
        .then((result) => {
          setIsLoading(false);
          if (result?.status === 200) {
            (() => {
              toast.success(MESSAGE_USERS.UPDATE);
              setTimeout(() => {
                navigate('/search-user');
              }, 1000);
            })();
          } else {
            (() => toast.error(MESSAGE_USERS.UPDATE_ERROR))();
          }
        })
        .catch((error) => {
          (() => toast.error(MESSAGE_USERS.UPDATE_ERROR))();
          setIsLoading(false);
        });
    }
  };

  const props = {
    handleSubmit,
    register,
    errors,
    isValid,
    showPassword,
    showPasswordConfirm,
    handleClickShowPassword,
    pass,
    onSubmit,
    isEdit,
    isLoading,
    updatePass,
    setUpdatePass,
    isDisabled,
    setIsEnabled,
    isEnabled,
  };
  return <View {...props} />;
};
