import React from 'react';

import { Table } from '../table';
import styles from './styles.module.scss';

export const View = ({ allUsers, columns, isLoading }) => {
  return (
    <>
      <div className={styles['search']}>
        <h2>Consultar Usuarios</h2>
        <Table rows={allUsers} columns={columns} loading={isLoading} />
      </div>
    </>
  );
};
