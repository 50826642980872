import React from 'react';
import { Routes, Route } from 'react-router-dom';
import App from '../App';
import { RegisterDocument } from '../components/registerDocument';
import { Search } from '../components/search';
import { RegisterUser } from '../components/registerUser';
import { Layout } from '../components/layout';
import { SearchUsers } from '../components/searchUsers';
import { UpdateUser } from '../components/updateUser';

export const AppRouter = () => {
  return (
    <Layout>
      <Routes>
        <Route path="*" element={<App />} />
        <Route path="/register-document" element={<RegisterDocument />} />
        <Route path="/search" element={<Search />} />
        <Route path="/register-user" element={<RegisterUser />} />
        <Route path="/edit-user" element={<UpdateUser />} />
        <Route path="/search-user" element={<SearchUsers />} />
      </Routes>
    </Layout>
  );
};
