import moment from 'moment';

const messageRequire = 'Campo requerido';

export const MAX_DATE = moment().format().split('T')[0];
export const LIMIT = 10;
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const EXPIRED_TOKEN =
  'Su sesión ha terminado, por favor inicie nuevamente';
export const MESSAGE_LOGIN =
  'Sus credenciales son invalidas, despues de 3 intentos fallidos se deshabilitara el ususario y tendrá que contactarte con su agente';
export const MESSAGE_USERS = {
  SUCCESS: '',
  FAILURE: 'Ocurrió un error cargando los usuarios',
  DELETE: 'Se eliminó el ususario exitosamente',
  DELETE_ERROR: 'Ocurrió un error eliminando el ususario ',
  UPDATE: 'Se actualizó el usuario exitosamente',
  UPDATE_ERROR: 'Ocurrió un error actualizando el usuario',
  CREATE: 'Se creó el usuario exitosamente',
  CREATE_ERROR: 'Ocurrió un error creando el usuario',
};
export const MESSAGE_DOCUMENTS = {
  LOAD: 'Se cargó el documento exitosamente',
  LOAD_ERROR: 'Ocurrió un error cargando el documento',
  FILTER_ERROR: 'Ocurrió un error realizando la busqueda',
  FILTER_NOT_FOUND: 'No existen documentos para los filtros aplicados',
  DOWNLOAD_ERROR: 'Ocurrió un error descargando el documento',
  DELETE: 'Se eliminó exitosamente el documento',
  DELETE_ERROR: 'No se pudo eliminar el documento',
};

export const FIELDS = {
  company: {
    name: 'company',
    label: 'Empresa',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  plate: {
    name: 'plate',
    label: 'Placa',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
      maxLength: {
        value: 6,
        message: 'Máximo de caracteres son 6',
      },
      pattern: {
        value: /^[0-9a-zA-Z]+$/,
        message: 'Debe contener solo números y letras',
      },
    },
  },
  typeVehicle: {
    name: 'typeVehicle',
    label: 'Tipo',
    options: [
      { id: 'LIVIANO', value: 'LIVIANO', label: 'LIVIANO' },
      { id: 'PESADO', value: 'PESADO', label: 'PESADO' },
      { id: 'MOTO', value: 'MOTO', label: 'MOTO' },
    ],
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  typeDocument: {
    name: 'typeDocument',
    label: 'Tipo de documento',
    options: [
      { id: 'CERTIFICADO', value: 'CERTIFICADO', label: 'CERTIFICADO' },
      { id: 'PREVENTIVA', value: 'PREVENTIVA', label: 'PREVENTIVA' },
      { id: 'FACTURA', value: 'FACTURA', label: 'FACTURA' },
      { id: 'FUR_RTM', value: 'FUR_RTM', label: 'FUR RTM' },
    ],
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  typeDocumentSearch: {
    name: 'typeDocument',
    label: 'Tipo de documento',
    options: [
      { id: 'CERTIFICADO', value: 'CERTIFICADO', label: 'CERTIFICADO' },
      { id: 'PREVENTIVA', value: 'PREVENTIVA', label: 'PREVENTIVA' },
      { id: 'FACTURA', value: 'FACTURA', label: 'FACTURA' },
      { id: 'FUR_RTM', value: 'FUR_RTM', label: 'FUR RTM' },
    ],
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  date: {
    type: 'date',
    name: 'date',
    label: 'Fecha emisión de documento',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  uploadFile: {
    type: 'file',
    name: 'uploadFile',
    label: 'Cargar documento',
    accept: '.pdf',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  user: {
    name: 'user',
    label: 'Usuario',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  password: {
    name: 'password',
    label: 'Contraseña',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  name: {
    name: 'name',
    label: 'Nombre',
    type: 'text',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
      minLength: {
        value: 3,
        message: 'Mínimo de caracteres son 3',
      },
    },
  },
  nit: {
    name: 'nit',
    label: 'NIT o Cédula',
    placeholder: 'Sin puntos, guiones o número de verificación',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
      pattern: {
        value: /^([0-9])*$/,
        message: 'Solo números',
      },
      maxLength: {
        value: 10,
        message: 'Máximo 10 digitos',
      },
      minLength: {
        value: 5,
        message: 'Mínimo de caracteres son 5',
      },
    },
  },
  typeUser: {
    name: 'typeUser',
    label: 'Tipo de Usuario',
    options: [
      { id: 1, value: 'ROLE_USER', label: 'CLIENTE' },
      { id: 2, value: 'ROLE_ADMIN', label: 'ADMIN' },
    ],
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  passCreate: {
    name: 'passCreate',
    label: 'Contraseña',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
      pattern: {
        value:
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/,
        message:
          'Minimo 8 caracteres - Maximo 15 - Al menos una letra mayúscula - Al menos una letra minucula - Al menos un dígito - No espacios en blanco - Al menos 1 caracter especial',
      },
    },
  },
  passConfirm: {
    name: 'passConfirm',
    label: 'Confirmar contraseña',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  searchUser: {
    name: 'searchUser',
    label: 'Buscar por',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
    options: [
      { id: 1, value: 'nit', label: 'Nit' },
      { id: 2, value: 'name', label: 'Nombre' },
    ],
  },
  searchUserByNit: {
    name: 'searchUserByNit',
    label: 'NIT o Cédula',
    placeholder: 'Sin puntos, guiones o número de verificación',
    rules: {
      pattern: {
        value: /^([0-9])*$/,
        message: 'Solo números',
      },
      maxLength: {
        value: 10,
        message: 'Máximo 10 digitos',
      },
      minLength: {
        value: 5,
        message: 'Mínimo de caracteres son 5',
      },
    },
  },
  searchUserByName: {
    name: 'searchUserByName',
    label: 'Nombre',
    type: 'text',
    rules: {
      minLength: {
        value: 3,
        message: 'Mínimo de caracteres son 3',
      },
    },
  },
  dateStart: {
    type: 'date',
    name: 'dateStart',
    label: 'Buscar desde',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  dateEnd: {
    type: 'date',
    name: 'dateEnd',
    label: 'Buscar hasta',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
  searchDate: {
    type: 'date',
    name: 'searchDate',
    label: 'Elija una fecha a buscar',
    rules: {
      required: {
        value: true,
        message: messageRequire,
      },
    },
  },
};
