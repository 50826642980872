import { useContext, useEffect, useState } from 'react';
import { hasTokenExpired } from '../util/hasTokenExpired';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contex/userContext';

import { EXPIRED_TOKEN, MESSAGE_USERS } from '../util/constants';
import { getAllUsers } from '../services';
import { toast } from 'react-toastify';

export const useAllUsers = (isCall = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!hasTokenExpired()) {
      (() => toast.error(EXPIRED_TOKEN))();
      setTimeout(() => navigate('/'), 1000);
    } else {
      isCall ? setIsLoading(true) : setIsLoading(false);
      isCall &&
        user?.token?.length > 0 &&
        getAllUsers(user?.token)
          .then((result) => {
            setIsLoading(false);
            setUsers(result?.data || []);
          })
          .catch((error) => {
            (() => toast.error(MESSAGE_USERS.FAILURE))();
            setIsLoading(false);
          });
    }
  }, [hasTokenExpired(), user]);

  return { users, isLoading };
};
