import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { FIELDS } from '../../util/constants';
import styles from './styles.module.scss';
import { Loading } from '../loading';

export const View = ({
  handleSubmit,
  register,
  errors,
  isValid,
  showPassword,
  showPasswordConfirm,
  handleClickShowPassword,
  pass,
  onSubmit,
  isLoading,
}) => {
  return (
    <div className={styles['container']}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
        <h2>Registrar Usuario</h2>

        <label htmlFor={FIELDS.name.name} className={styles['form-label']}>
          {FIELDS.name.label}
        </label>
        <input
          {...FIELDS.name}
          {...register(FIELDS.name.name, { ...FIELDS.name.rules })}
          id={FIELDS.name.name}
          className={`${styles['form-fields']} ${styles['form-fields-upperscase']}`}
        />
        {errors?.name?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.name?.message} </span>
          </p>
        )}
        {errors?.name?.type === 'minLength' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.name?.message} </span>
          </p>
        )}

        <label htmlFor={FIELDS.nit.name} className={styles['form-label']}>
          {FIELDS.nit.label}
        </label>
        <input
          {...FIELDS.nit}
          {...register(FIELDS.nit.name, { ...FIELDS.nit.rules })}
          id={FIELDS.nit.name}
          className={styles['form-fields']}
        />
        {errors?.nit?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.nit?.message} </span>
          </p>
        )}
        {errors?.nit?.type === 'pattern' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.nit?.message} </span>
          </p>
        )}
        {errors?.nit?.type === 'maxLength' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.nit?.message} </span>
          </p>
        )}
        {errors?.nit?.type === 'minLength' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.nit?.message} </span>
          </p>
        )}

        <label htmlFor={FIELDS.typeUser.name} className={styles['form-label']}>
          {FIELDS.typeUser.label}
        </label>
        <select
          id={FIELDS.typeUser.name}
          {...FIELDS.typeUser}
          {...register(FIELDS.typeUser.name, { ...FIELDS.typeUser.rules })}
          className={styles['form-fields']}
        >
          {FIELDS.typeUser.options.map((e) => (
            <option value={e.value} key={e.id}>
              {e.label}
            </option>
          ))}
        </select>
        {errors?.typeUser?.type === 'required' && (
          <p className={styles['form-error']}>
            <ErrorOutlineOutlinedIcon fontSize="small" />
            <span>{errors?.typeUser?.message} </span>
          </p>
        )}

        <label
          htmlFor={FIELDS.passCreate.name}
          className={styles['form-label']}
        >
          {FIELDS.passCreate.label}
        </label>
        <div className={styles['form-pass']}>
          <input
            type={showPassword ? 'text' : 'password'}
            {...FIELDS.passCreate}
            {...register(FIELDS.passCreate.name, {
              ...FIELDS.passCreate.rules,
            })}
            id={FIELDS.passCreate.name}
            className={styles['form-fields']}
          />
          <button
            onClick={(e) => handleClickShowPassword(e, true)}
            className={styles['form-show']}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </button>
          {errors?.passCreate?.type === 'required' && (
            <p className={styles['form-error']}>
              <ErrorOutlineOutlinedIcon fontSize="small" />
              <span>{errors?.passCreate?.message} </span>
            </p>
          )}
          {errors?.passCreate?.type === 'pattern' && (
            <p className={styles['form-error']}>
              <ErrorOutlineOutlinedIcon fontSize="small" />
              <span>{errors?.passCreate?.message}</span>
            </p>
          )}
        </div>

        <label
          htmlFor={FIELDS.passConfirm.name}
          className={styles['form-label']}
        >
          {FIELDS.passConfirm.label}
        </label>
        <div className={styles['form-pass']}>
          <input
            type={showPasswordConfirm ? 'text' : 'password'}
            {...FIELDS.passConfirm}
            {...register(FIELDS.passConfirm.name, {
              validate: (value) => {
                if (value !== pass) {
                  return 'Las contraseñas no coinciden';
                }
              },
              ...FIELDS.passConfirm.rules,
            })}
            id={FIELDS.passConfirm.name}
            className={styles['form-fields']}
          />
          <button
            onClick={(e) => handleClickShowPassword(e, false)}
            className={styles['form-show']}
          >
            {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
          </button>
          {errors?.passConfirm?.type === 'required' && (
            <p className={styles['form-error']}>
              <ErrorOutlineOutlinedIcon fontSize="small" />
              <span>{errors?.passConfirm?.message} </span>
            </p>
          )}
          {errors?.passConfirm?.type === 'validate' && (
            <p className={styles['form-error']}>
              <ErrorOutlineOutlinedIcon fontSize="small" />
              {errors?.passConfirm?.message}
            </p>
          )}
        </div>

        {isLoading ? (
          <div className={styles['form-spinner']}>
            <Loading />
          </div>
        ) : (
          <input
            type="submit"
            disabled={!isValid}
            className={`${
              !isValid ? styles['form-submit-disabled'] : styles['form-submit']
            } ${styles['form-submit-margin']}`}
          />
        )}
      </form>
    </div>
  );
};
