import axios from 'axios';
import { LIMIT } from '../util/constants';
import configData from '../config.json';

const apiURL = process.env.REACT_APP_API_URL;

//files
export const downloadFile = ({ idFile, token }) =>
  axios.get(`${apiURL}/api/v1/download/${idFile}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

export const loadFile = ({ formdata, token }) =>
  axios.post(`${apiURL}/api/v1/upload`, formdata, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
  });

export const filterBetweenDate = ({
  userId,
  plate,
  typeDocument,
  dateStart,
  dateEnd,
  page,
  token,
}) =>
  axios.get(
    `${apiURL}/api/v1/files-between-dates?user_id=${userId}&placa=${plate}&cda_document_type=${typeDocument}&from_date=${dateStart}&to_date=${dateEnd}&page_number=${page}&page_size=${LIMIT}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

export const filterDate = ({
  userId,
  plate,
  typeDocument,
  searchDate,
  page,
  token,
}) =>
  axios.get(
    `${apiURL}/api/v1/files?user_id=${userId}&placa=${plate}&cda_document_type=${typeDocument}&expedition_date=${searchDate}&page_number=${page}&page_size=${LIMIT}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

export const deleteDocument = ({ token, id }) =>
  axios.delete(`${apiURL}/api/v1/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

//Auth

export const login = (urlencoded) =>
  axios.post(`${apiURL}/security/oauth/token`, urlencoded, {
    headers: {
      Authorization: configData.AUTH_CLIENT_ID,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

//User
export const getAllUsers = (token) =>
  axios.get(`${apiURL}/users`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

export const deleteUser = ({ token, id }) =>
  axios.delete(`${apiURL}/users/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

export const updateUser = ({ nit, raw, token }) =>
  axios.put(`${apiURL}/users/update/${nit}`, raw, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  });

export const createUser = ({ raw, token }) =>
  axios.post(`${apiURL}/users`, raw, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  });
