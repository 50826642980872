import React from 'react';
import Pagination from '@mui/material/Pagination';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';

import logo from '../../assets/logocda.png';
import { FIELDS, MAX_DATE } from '../../util/constants';
import { Table } from '../table';
import styles from './styles.module.scss';
import { Loading } from '../loading';
import { useMediaQuery } from 'react-responsive';

export const View = ({
  handleSubmit,
  register,
  columns,
  errors,
  allUsers,
  onSubmit,
  isAdmin,
  seachRageDates,
  setSeachRageDates,
  rowsData,
  setPage,
  isLoading,
  dateStart,
  isLoadingDownload,
  isAdminUser,
}) => {
  const isMobileTablet = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      <h2>Consultar Documentos</h2>
      <div className={styles['search']}>
        <div className={styles['container']}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
            {isAdmin && (
              <>
                <label
                  htmlFor={FIELDS.company.name}
                  className={styles['form-label']}
                >
                  {FIELDS.company.label}
                </label>
                <select
                  id={FIELDS.company.name}
                  {...FIELDS.company}
                  {...register(FIELDS.company.name, {
                    ...FIELDS.company.rules,
                  })}
                  className={styles['form-fields']}
                >
                  <option value="" disabled selected>
                     Selecciona un cliente
                  </option>
                  {allUsers?.map((e) => (
                    <option value={e.value} key={e.id}>
                      {e.label}
                    </option>
                  ))}
                </select>
                {errors?.company?.type === 'required' && (
                  <p className={styles['form-error']}>
                    <ErrorOutlineOutlinedIcon fontSize="small" />
                    <span>{errors?.company?.message} </span>
                  </p>
                )}
              </>
            )}

            <label htmlFor={FIELDS.plate.name} className={styles['form-label']}>
              {FIELDS.plate.label}
            </label>
            <input
              id={FIELDS.plate.name}
              {...FIELDS.plate}
              {...register(FIELDS.plate.name)}
              className={`${styles['form-fields']} ${styles['form-fields-upperscase']}`}
            />

            <label
              htmlFor={FIELDS.typeDocumentSearch.name}
              className={styles['form-label']}
            >
              {FIELDS.typeDocumentSearch.label}
            </label>
            <select
              id={FIELDS.typeDocumentSearch.name}
              {...FIELDS.typeDocumentSearch}
              {...register(FIELDS.typeDocumentSearch.name, {
                ...FIELDS.typeDocumentSearch.rules
              })}
              className={styles['form-fields']}
            >
              {FIELDS.typeDocumentSearch.options.map((e) => (
                <option value={e.value} key={e.id}>
                  {e.label}
                </option>
              ))}
            </select>

            <div className={styles['form-check']}>
              <input
                id="check"
                type="checkbox"
                onClick={() => setSeachRageDates(!seachRageDates)}
              />
              <label htmlFor="check">Buscar en un rango de fechas</label>
            </div>

            {seachRageDates ? (
              <>
                <div className={styles['info-container']}>
                  <InfoIcon fontSize='small' sx={{ color: "#007aff" }} />
                  <span className={styles['info-container-text']}>
                    Solo se permite hacer busquedas en un rango de fechas máximo de un (1) mes.
                  </span>
                </div>
                <label
                  htmlFor={FIELDS.dateStart.name}
                  className={styles['form-label']}
                >
                  {FIELDS.dateStart.label}
                </label>
                <input
                  type="date"
                  max={MAX_DATE}
                  {...FIELDS.dateStart}
                  {...register(FIELDS.dateStart.name, { ...FIELDS.dateEnd.rules })}
                  id={FIELDS.date.name}
                  className={styles['form-fields']}
                />
                {errors?.dateStart?.type === 'required' && (
                  <p className={styles['form-error']}>
                    <ErrorOutlineOutlinedIcon fontSize="small" />
                    <span>{errors?.dateStart?.message} </span>
                  </p>
                )}
                <label
                  htmlFor={FIELDS.dateEnd.name}
                  className={styles['form-label']}
                >
                  {FIELDS.dateEnd.label}
                </label>
                <input
                  type="date"
                  max={MAX_DATE}
                  {...FIELDS.dateEnd}
                  {...register(FIELDS.dateEnd.name, {
                    ...FIELDS.dateEnd.rules,
                    validate: (value) => {
                      const dateEnd = moment(value);
                      const dateInitial = moment(dateStart);
                      const monthDiff = dateEnd.diff(dateInitial, 'months', true);
                      if (dateEnd < dateInitial) {
                        return 'La fecha es menor a la seleccionada anteriormente.';
                      }
                      if (monthDiff > 1) {
                        return 'Rango de fechas seleccionado mayor a un (1) mes.'
                      }
                    },
                  })}
                  id={FIELDS.dateEnd.name}
                  className={styles['form-fields']}
                />
                {errors?.dateEnd?.type === 'validate' && (
                  <p className={styles['form-error']}>
                    <ErrorOutlineOutlinedIcon fontSize="small" />
                    {errors?.dateEnd?.message}
                  </p>
                )}
                {errors?.dateEnd?.type === 'required' && (
                  <p className={styles['form-error']}>
                    <ErrorOutlineOutlinedIcon fontSize="small" />
                    <span>{errors?.dateEnd?.message} </span>
                  </p>
                )}
              </>
            ) : (
              <>
                <label
                  htmlFor={FIELDS.searchDate.name}
                  className={styles['form-label']}
                >
                  {FIELDS.searchDate.label}
                </label>
                <input
                  type="date"
                  max={MAX_DATE}
                  {...FIELDS.searchDate}
                  {...register(FIELDS.searchDate.name, {
                    ...FIELDS.searchDate.rules,
                  })}
                  id={FIELDS.searchDate.name}
                  className={styles['form-fields']}
                />
                {errors?.searchDate?.type === 'required' && (
                  <p className={styles['form-error']}>
                    <ErrorOutlineOutlinedIcon fontSize="small" />
                    <span>{errors?.searchDate?.message} </span>
                  </p>
                )}
              </>
            )}
            {isLoading || isLoadingDownload ? (
              <div className={styles['form-spinner']}>
                <Loading />
              </div>
            ) : (
              <button
                type="submit"
                className={`${styles['form-submit']} ${styles['form-submit-margin']}`}
                onClick={() => setPage(0)}
              >
                Buscar
              </button>
            )}
          </form>
          {isAdminUser
            ? null
            : !isMobileTablet && <img src={logo} alt="logo cda" />}
        </div>

        <div className={styles['table']}>
          <Table
            rows={rowsData?.infoRows}
            columns={columns}
            loading={isLoading || isLoadingDownload}
          />
          {rowsData?.totalPages !== 0 && (
            <Pagination
              count={rowsData?.totalPages}
              onChange={(event, page) => {
                setPage(page - 1);
              }}
              disabled={isLoading || isLoadingDownload}
            />
          )}
        </div>
      </div>
    </>
  );
};
