import React, { useContext } from 'react';
import styles from './styles.module.scss';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logocda.png';
import { Burger } from '../burger';
import { useMediaQuery } from 'react-responsive';
import { Menu } from './Menu';
import { UserContext } from '../../contex/userContext';
import { ROLE_ADMIN } from '../../util/constants';

export const Header = () => {
  const location = useLocation();
  const showMenu = location?.pathname !== '/';
  const { user } = useContext(UserContext);
  const isAdmin = user.role === ROLE_ADMIN;
  const isMobileTablet = useMediaQuery({ maxWidth: 768 });
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const getInfoForRender = () => {
    if (isDesktop) return <Menu />;
    if (isMobileTablet) return <Burger />;
  };
  return (
    <div className={showMenu ? styles['header'] : styles['none']}>
      <Link
        to={showMenu ? '/search' : 'https://www.cdacarmotossas.com/'}
        target={!showMenu ? '_blank' : '_top'}
        className={styles['header-nav-link']}
      >
        {isAdmin ? (
          <img src={logo} alt="Logo CDA" />
        ) : (
          isMobileTablet && <img src={logo} alt="Logo CDA" />
        )}
      </Link>
      {showMenu && getInfoForRender()}
    </div>
  );
};
