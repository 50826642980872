import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

export const View = ({ rows, columns, loading, hideFooter }) => {
  return (
    <div style={{ height: 400, width: '100%', margin: '40px 0' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter={hideFooter}
        loading={loading}
      />
    </div>
  );
};
