import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../contex/userContext';
import { getPayloadJwt } from '../../util/getPayloadJwt';
import { MESSAGE_LOGIN, ROLE_ADMIN } from '../../util/constants';
import { View } from './view';
import { login } from '../../services';
import { toast } from 'react-toastify';

export const LoginScreen = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      user: '',
      password: '',
    },
  });

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    return setShowPassword((show) => !show);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    let urlencoded = new URLSearchParams();
    urlencoded.append('username', data.user);
    urlencoded.append('password', data.password);
    urlencoded.append('grant_type', 'password');

    login(urlencoded)
      .then((result) => {
        setIsLoading(false);
        setUser({
          token: result?.data?.access_token,
          userId: result?.data?.user_id,
          role: getPayloadJwt(result?.data?.access_token).authorities[0],
          isAdmin:
            getPayloadJwt(result?.data?.access_token).authorities[0] ===
            ROLE_ADMIN,
        });
        sessionStorage.setItem('token', result?.data?.access_token);
        sessionStorage.setItem(
          'user',
          JSON.stringify({
            token: result?.data?.access_token,
            userId: result?.data?.user_id,
            role: getPayloadJwt(result?.data?.access_token).authorities[0],
            isAdmin:
              getPayloadJwt(result?.data?.access_token).authorities[0] ===
              ROLE_ADMIN,
          })
        );
        navigate('/search');
      })
      .catch((error) => {
        (() => toast.error(MESSAGE_LOGIN))();
        setIsLoading(false);
      });
  };

  const props = {
    handleSubmit,
    register,
    errors,
    isValid,
    onSubmit,
    showPassword,
    handleClickShowPassword,
    isLoading,
  };

  return <View {...props} />;
};
