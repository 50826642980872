import React from 'react';
import { Header } from '../header';
import { useLocation } from 'react-router-dom';
import { LoginScreen } from '../login';

export const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const isLogin = pathname === '/';

  return (
    <>
      {isLogin ? (
        <LoginScreen />
      ) : (
        <>
          <Header />
          {children}
        </>
      )}
    </>
  );
};
