import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { FIELDS } from '../../util/constants';
import { Loading } from '../loading';
import logo from '../../assets/logocda.png';
import styles from './styles.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Header } from '../header';

export const View = ({
  handleSubmit,
  register,
  errors,
  isValid,
  onSubmit,
  showPassword,
  handleClickShowPassword,
  isLoading,
}) => {
  const isMobileTablet = useMediaQuery({ maxWidth: 768 });
  return (
    <div className={!isMobileTablet ? styles['container-all'] : null}>
      {isMobileTablet ? <Header /> : <img src={logo} alt="logo CDA" />}
      <div
        className={
          !isMobileTablet ? styles['container'] : styles['container-responsive']
        }
      >
        <form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
          <h2>Iniciar sesión</h2>

          <label htmlFor={FIELDS.user.name} className={styles['form-label']}>
            {FIELDS.user.label}
          </label>
          <input
            {...FIELDS.user}
            {...register(FIELDS.user.name, {
              ...FIELDS.user.rules,
            })}
            id={FIELDS.user.name}
            className={styles['form-fields']}
          />
          {errors?.user?.type === 'required' && (
            <p className={styles['form-error']}>
              <ErrorOutlineOutlinedIcon fontSize="small" />
              <span>{errors?.user?.message} </span>
            </p>
          )}

          <label
            htmlFor={FIELDS.password.name}
            className={styles['form-label']}
          >
            {FIELDS.password.label}
          </label>
          <div className={styles['form-pass']}>
            <input
              type={showPassword ? 'text' : 'password'}
              {...FIELDS.password}
              {...register(FIELDS.password.name, {
                ...FIELDS.password.rules,
              })}
              id={FIELDS.password.name}
              className={styles['form-fields']}
            />
            <button
              onClick={(e) => handleClickShowPassword(e, true)}
              className={styles['form-show']}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
            {errors?.password?.type === 'required' && (
              <p className={styles['form-error']}>
                <ErrorOutlineOutlinedIcon fontSize="small" />
                <span>{errors?.password?.message} </span>
              </p>
            )}
          </div>

          {isLoading ? (
            <div className={styles['form-spinner']}>
              <Loading />
            </div>
          ) : (
            <input
              type="submit"
              disabled={!isValid}
              className={`${
                !isValid
                  ? styles['form-submit-disabled']
                  : styles['form-submit']
              } ${styles['form-submit-margin']}`}
            />
          )}
        </form>
      </div>
    </div>
  );
};
