import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../contex/userContext';
import { ROLE_ADMIN } from '../../util/constants';
import { getPayloadJwt } from '../../util/getPayloadJwt';
import styles from './styles.module.scss';
import PersonIcon from '@mui/icons-material/Person';

export const Menu = () => {
  const location = useLocation();
  const showMenu = location?.pathname !== '/';
  const { user } = useContext(UserContext);
  const isAdmin = user.role === ROLE_ADMIN;
  const name = getPayloadJwt(user?.token)?.name;
  return (
    <>
      {showMenu && (
        <div>
          <nav>
            <ul className={styles['header-nav']}>
              {isAdmin && (
                <>
                  <li>
                    <Link
                      to={'/register-user'}
                      className={styles['header-nav-link']}
                    >
                      Registrar Usuario
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/search-user'}
                      className={styles['header-nav-link']}
                    >
                      Consultar Usuarios
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/register-document'}
                      className={styles['header-nav-link']}
                    >
                      Registrar Documento
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link to={'/search'} className={styles['header-nav-link']}>
                  Consultar Documentos
                </Link>
              </li>
              <li className={styles['header-user']}>
                <div className={styles['header-user-name']}>
                  <PersonIcon fontSize="large" />
                  <span>{name}</span>
                </div>
                <ul>
                  <li
                    onClick={() => {
                      sessionStorage.clear();
                    }}
                  >
                    <Link to={'/'} className={styles['header-nav-link']}>
                      Cerrar Sesión
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};
