import React, { useContext, useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';

import { View } from './view';
import { UserContext } from '../../contex/userContext';
import styles from './styles.module.scss';
import { useAllUsers } from '../../hooks/useAllUsers';
import { deleteUser } from '../../services';
import { EXPIRED_TOKEN, MESSAGE_USERS } from '../../util/constants';
import { toast } from 'react-toastify';
import { hasTokenExpired } from '../../util/hasTokenExpired';
import { DeleteModal } from '../deleteModal';

export const SearchUsers = () => {
  const { user } = useContext(UserContext);
  const { users, isLoading } = useAllUsers();
  const [isOpen, setOpen] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const handleOpen = (data) => {
    setDataDelete(data)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const allUsers = useMemo(() => {
    return (
      users?.map((e, i) => {
        return {
          id: i + 1,
          name: e.name,
          nit: e.user_id,
          type: e.role.name === 'ROLE_ADMIN' ? 'Admin' : 'Cliente',
          user: e.username,
          enabled: e.enabled ? 'Si' : 'No',
        };
      }) || []
    );
  }, [users]);

  const handlerDelete = (id) => {
    if (!hasTokenExpired()) {
      (() => toast.error(EXPIRED_TOKEN))();
      setTimeout(() => navigate('/'), 1000);
    } else {
      deleteUser({ token: user.token, id: id })
        .then((response) => {
          if (response?.status === 200) {
            (() => {
              toast.success(MESSAGE_USERS.DELETE);
              handleClose();
              setTimeout(() => {
                navigate('/search');
              }, 1000);
            })();
          } else {
            (() => toast.error(MESSAGE_USERS.DELETE_ERROR))();
          }
        })
        .catch((error) => {
          (() => toast.error(MESSAGE_USERS.DELETE_ERROR))();
        });
    }
  };
  const columns = [
    { field: 'id', headerName: '', width: 40 },
    { field: 'name', headerName: 'Nombre', width: 300 },
    { field: 'nit', headerName: 'Nit', width: 150 },
    { field: 'type', headerName: 'Tipo', width: 80 },
    { field: 'user', headerName: 'Usuario', width: 150 },
    { field: 'enabled', headerName: 'Habilitado', width: 120 },
    {
      field: 'edit',
      headerName: '',
      width: 40,
      renderCell: (params) => {
        return (
          <div>
            <Link to={'/edit-user'} state={params.row}>
              <EditIcon index={params.row.id} />
            </Link>
          </div>
        );
      },
    },
    {
      field: 'delete',
      headerName: '',
      width: 60,
      renderCell: (params) => {
        return (
          <div className={styles['icon-delete']}>
            <DeleteModal
              id={dataDelete.nit}
              handlerDelete={handlerDelete}
              handleClose={handleClose}
              handleOpen={()=>handleOpen(params?.row)}
              isOpen={isOpen}
              message={`¿Está seguro de eliminar el ususario ${dataDelete.name}?`}
            />
          </div>
        );
      },
    },
  ];

  const props = {
    allUsers,
    columns,
    isLoading,
  };
  return <View {...props} />;
};
