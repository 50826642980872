import React, { useContext, useState } from 'react';
import { View } from './view';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../contex/userContext';
import { hasTokenExpired } from '../../util/hasTokenExpired';
import { createUser } from '../../services';
import { EXPIRED_TOKEN, MESSAGE_USERS } from '../../util/constants';
import { toast } from 'react-toastify';

export const RegisterUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      nit: '',
      typeUser: 'ROLE_USER',
      email: '',
      passCreate: '',
      passConfirm: '',
    },
  });

  const handleClickShowPassword = (e, isPassCreate) => {
    e.preventDefault();
    return isPassCreate
      ? setShowPassword((show) => !show)
      : setShowPasswordConfirm((show) => !show);
  };
  const pass = watch('passCreate');

  const onSubmit = async (data) => {
    if (!hasTokenExpired()) {
      (() => toast.error(EXPIRED_TOKEN))();
      setTimeout(() => navigate('/'), 1000);
    } else {
      setIsLoading(true);
      let raw = JSON.stringify({
        user_id: data?.nit,
        name: data?.name?.toUpperCase(),
        username: data?.nit,
        password: data?.passCreate,
        role: {
          name: data.typeUser,
        },
      });
      createUser({ raw: raw, token: user.token })
        .then((result) => {
          setIsLoading(false);
          if (result?.status === 201) {
            (() => {
              toast.success(MESSAGE_USERS.CREATE);
              setTimeout(() => {
                navigate('/search-user');
              }, 1000);
            })();
          } else {
            (() => toast.error(MESSAGE_USERS.CREATE_ERROR))();
          }
        })
        .catch((error) => {
          (() => toast.error(MESSAGE_USERS.CREATE_ERROR))();
          setIsLoading(false);
        });
    }
  };

  const props = {
    handleSubmit,
    register,
    errors,
    isValid,
    showPassword,
    showPasswordConfirm,
    handleClickShowPassword,
    pass,
    onSubmit,
    isLoading,
  };
  return <View {...props} />;
};
