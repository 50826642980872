import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './styles.module.scss';
import { Menu } from '../header/Menu';

export const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuIcon
        onClick={() => setOpen(!open)}
        fontSize="large"
        className={styles['icon']}
      />
      <div className={open ? styles['burger'] : styles['burger-close']}>
        <ClearIcon
          onClick={() => setOpen(!open)}
          fontSize="large"
          className={styles['icon-close']}
        />
        <Menu />
      </div>
    </>
  );
};
