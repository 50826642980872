import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';

import { UserContext } from '../../contex/userContext';
import {
  EXPIRED_TOKEN,
  MESSAGE_DOCUMENTS,
  ROLE_ADMIN,
} from '../../util/constants';
import { formatDate } from '../../util/formatDate';
import { hasTokenExpired } from '../../util/hasTokenExpired';
import { useAllUsers } from '../../hooks/useAllUsers';
import { View } from './view';
import styles from './styles.module.scss';
import {
  deleteDocument,
  downloadFile,
  filterBetweenDate,
  filterDate,
} from '../../services';
import { toast } from 'react-toastify';
import { DeleteModal } from '../deleteModal';

const DEFAULT_VALUES = {
  totalResults: 0,
  totalPages: 0,
  infoRows: [],
};

export const Search = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [seachRageDates, setSeachRageDates] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsData, setRowsData] = useState(DEFAULT_VALUES);
  const [isOpen, setOpen] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const handleOpen = (data) => {
    setDataDelete(data)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const {
    user,
    user: { isAdmin },
  } = useContext(UserContext);
  const { users } = useAllUsers(isAdmin);
  const isAdminUser = user.role === ROLE_ADMIN;
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      company: '',
      plate: '',
      dateEnd: '',
      dateStart: '',
    },
  });
  const dateStart = watch('dateStart');

  const allUsers = useMemo(() => {
    return users?.map((e) => {
      return {
        id: e?.user_id,
        label: e?.name,
        value: e?.user_id,
      };
    })
  }, [users]);

  const handleDownload = (file, plate) => {
    setIsLoadingDownload(true);
    downloadFile({
      idFile: file,
      token: user?.token,
    })
      .then((response) => {
        setIsLoadingDownload(false);
        window.open(response.data?.document_download_url, '_blank')
      })
      .catch((error) => {
        (() => toast.error(MESSAGE_DOCUMENTS.DOWNLOAD_ERROR))();
        setIsLoadingDownload(false);
      });
  };

  const handlerDelete = (id) => {
    if (!hasTokenExpired()) {
      (() => toast.error(EXPIRED_TOKEN))();
      setTimeout(() => navigate('/'), 1000);
    } else {
      deleteDocument({ token: user.token, id: id })
        .then((response) => {
          if (response?.status === 200) {
            (() => {
              toast.success(MESSAGE_DOCUMENTS.DELETE);
              handleClose();
              onSubmit(getValues());
            })();
          } else {
            (() => toast.error(MESSAGE_DOCUMENTS.DELETE_ERROR))();
          }
        })
        .catch((error) => {
          (() => toast.error(MESSAGE_DOCUMENTS.DELETE_ERROR))();
        });
    }
  };

  const getColumns = () => {
    let columns = [
      { isVisible:true, field: 'id', headerName: '', width: 70 },
      { isVisible:true, field: 'placa', headerName: 'Placa', width: 150 },
      { isVisible:true, field: 'tipo', headerName: 'Tipo', width: 150 },
      { isVisible:true, field: 'fecha', headerName: 'Fecha', width: 150 },
      { 
        isVisible:true,
        field: 'archivo',
        headerName: 'Documento',
        width: 100,
        renderCell: (params) => {
          return (
            <div>
              <button
                className={styles['icon-down']}
                onClick={() => {
                  handleDownload(params.row.archivo, params.row.placa);
                }}
              >
                <DownloadIcon fontSize="small" index={params.row.id} />
              </button>
            </div>
          );
        },
      },
      {
        isVisible: isAdmin,
        field: 'eliminar',
        headerName: '',
        width: 60,
        renderCell: (params) => {
          return (
            <div className={styles['icon-delete']}>
              <DeleteModal
                id={dataDelete.archivo}
                handlerDelete={handlerDelete}
                handleClose={handleClose}
                handleOpen={()=>handleOpen(params?.row)}
                isOpen={isOpen}
                message={`¿Está seguro de eliminar el documento ${dataDelete.tipo} relacionado a la placa ${dataDelete.placa}?`}
              />
            </div>
          );
        },
      },
    ];
    return columns.filter(e=> e.isVisible)
  }

  const columns=getColumns()
  
  const fetchRangeDatesSearch = (data) => {
    if (!hasTokenExpired()) {
      (() => toast.error(EXPIRED_TOKEN))();
      setTimeout(() => navigate('/'), 1000);
    } else {
      setIsLoading(true);
      filterBetweenDate({
        userId: isAdmin ? data?.company : user?.userId,
        plate: data?.plate.toUpperCase(),
        typeDocument: data?.typeDocument,
        dateStart: formatDate(data?.dateStart),
        dateEnd: formatDate(data?.dateEnd),
        page: page,
        token: user?.token,
      })
        .then((result) => {
          setIsLoading(false);
          if (result.status === 200) {
            setRowsData(() => {
              return {
                totalResults: result?.data?.total_elements,
                totalPages: result?.data?.total_pages,
                infoRows:
                  result?.data?.content?.map((e, i) => {
                    return {
                      id: i + 1,
                      placa: e.placa,
                      tipo: e.cda_document_type,
                      fecha: e.expedition_date,
                      archivo: e.id,
                    };
                  }) || DEFAULT_VALUES,
              };
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setRowsData(DEFAULT_VALUES);
          if (error?.response?.status === 400) {
            (() => toast.error(MESSAGE_DOCUMENTS.FILTER_NOT_FOUND))();
          } else {
            (() => toast.error(MESSAGE_DOCUMENTS.FILTER_ERROR))();
          }
        });
    }
  };

  const fetchDatesSearch = (data) => {
    if (!hasTokenExpired()) {
      (() => toast.error(EXPIRED_TOKEN))();
      setTimeout(() => navigate('/'), 1000);
    } else {
      setIsLoading(true);
      filterDate({
        userId: isAdmin ? data?.company : user?.userId,
        plate: data?.plate.toUpperCase(),
        typeDocument: data?.typeDocument,
        searchDate: formatDate(data?.searchDate),
        page: page,
        token: user?.token,
      })
        .then((result) => {
          setIsLoading(false);
          if (result.status === 200) {
            setRowsData(() => {
              return {
                totalResults: result?.data?.total_elements,
                totalPages: result?.data?.total_pages,
                infoRows:
                  result?.data?.content?.map((e, i) => {
                    return {
                      id: i + 1,
                      placa: e.placa,
                      tipo:
                        e.cda_document_type === 'FUR_RTM'
                          ? 'FUR RTM'
                          : e.cda_document_type,
                      fecha: e.expedition_date,
                      archivo: e.id,
                    };
                  }) || DEFAULT_VALUES,
              };
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setRowsData(DEFAULT_VALUES);
          if (error.response.status === 400) {
            (() => toast.error(MESSAGE_DOCUMENTS.FILTER_NOT_FOUND))();
          } else {
            (() => toast.error(MESSAGE_DOCUMENTS.FILTER_ERROR))();
          }
        });
    }
  };

  const onSubmit = (data) => {
    if (seachRageDates) {
      fetchRangeDatesSearch(data);
    } else {
      fetchDatesSearch(data);
    }
  };

  useEffect(() => {
    if (!(isAdmin && getValues().company === '')) {
      user?.token?.length > 0 && onSubmit(getValues());
    }
  }, [page]);

  const props = {
    handleSubmit,
    register,
    isValid,
    errors,
    onSubmit,
    columns,
    allUsers,
    isAdmin,
    seachRageDates,
    setSeachRageDates,
    rowsData,
    setPage,
    isLoading,
    dateStart,
    isLoadingDownload,
    isAdminUser,
  };
  return <View {...props} />;
};
